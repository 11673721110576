<template>
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Contatos</h1>
      <p class="text-gray-500">
        Lista de contatos para ajudar na integração da sua loja.
      </p>
    </header>
    <div class="divide-y space-y-8">
      <div class="space-y-5">
        <div>
          <h4 class="font-semibold md:mb-2">
            Responsável técnico
          </h4>
          <px-checkbox
            name="technicalManagerCheckbox"
            v-model="technicalManagerCheckbox"
            @input="handleCheckbox('technicalManager')"
            class="hidden md:block"
          >
            O mesmo dos dados de cadastro
          </px-checkbox>
        </div>
        <px-input
          v-model="technicalManagerName"
          name="techName"
          :disabled="technicalManagerCheckbox"
        >
          Nome
        </px-input>
        <px-input
          v-model="technicalManagerEmail"
          name="techEmail"
          type="email"
          :disabled="technicalManagerCheckbox"
        >
          Email
        </px-input>
      </div>

      <div class="space-y-5 pt-6">
        <div>
          <h4 class="font-semibold md:mb-2">
            Responsável financeiro
          </h4>
          <px-checkbox
            v-model="financeManagerCheckbox"
            @input="handleCheckbox('financeManager')"
            class="hidden md:block"
          >
            O mesmo dos dados de cadastro
          </px-checkbox>
        </div>
        <px-input
          v-model="financeManagerName"
          name="finName"
          :disabled="financeManagerCheckbox"
        >
          Nome
        </px-input>
        <px-input
          v-model="financeManagerEmail"
          name="finEmail"
          type="email"
          :disabled="financeManagerCheckbox"
        >
          Email
        </px-input>
      </div>

      <div class="space-y-5 pt-6">
        <div>
          <h4 class="font-semibold md:mb-2">
            Responsável de atendimento
          </h4>
          <px-checkbox
            v-model="serviceManagerCheckbox"
            @input="handleCheckbox('serviceManager')"
            class="hidden md:block"
          >
            O mesmo dos dados de cadastro
          </px-checkbox>
        </div>
        <px-input
          v-model="serviceManagerName"
          name="servName"
          :disabled="serviceManagerCheckbox"
        >
          Nome
        </px-input>
        <px-input
          v-model="serviceManagerEmail"
          name="servEmail"
          type="email"
          :disabled="serviceManagerCheckbox"
        >
          Email
        </px-input>
      </div>
    </div>
    <footer class="flex justify-end space-x-3 mt-6">
      <px-button
        kind="primary"
        class="w-full md:w-auto"
        @click="handleClick"
      >
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
  </template-onboarding>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import TemplateOnboarding from './TemplateOnboarding.vue';
import { PxButton, PxInput, PxCheckbox } from '../../../components/custom';
import utilValidator from '../../../util/util.validator';

export default {
  name: 'Contacts',
  metaInfo: {
    title: 'Contatos',
  },
  components: {
    TemplateOnboarding,
    PxButton,
    PxInput,
    PxCheckbox,
    ChevronRightIcon,
  },
  data: () => ({
    currentOnboardingStep: 4,
    technicalManagerCheckbox: false,
    technicalManagerName: '',
    technicalManagerEmail: '',
    financeManagerCheckbox: false,
    financeManagerName: '',
    financeManagerEmail: '',
    serviceManagerCheckbox: false,
    serviceManagerName: '',
    serviceManagerEmail: '',
  }),
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('onboarding', ['onboardingSteps', 'user']),
    ...mapGetters('onboarding', ['managersData']),
  },
  methods: {
    ...mapMutations('onboarding', ['setManagersData', 'getUserName', 'setUserName']),
    ...mapActions('onboarding', ['getUserById']),
    getInvalidInputList() {
      const list = [];
      if (!this.technicalManagerName) list.push('techName');
      if (!utilValidator.isValidEmail(this.technicalManagerEmail)) list.push('techEmail');
      if (!this.financeManagerName) list.push('finName');
      if (!utilValidator.isValidEmail(this.financeManagerEmail)) list.push('finEmail');
      if (!this.serviceManagerName) list.push('servName');
      if (!utilValidator.isValidEmail(this.serviceManagerEmail)) list.push('servEmail');
      return list;
    },
    handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        this.setManagersData({
          technicalManagerName: this.technicalManagerName,
          technicalManagerEmail: this.technicalManagerEmail,
          financeManagerName: this.financeManagerName,
          financeManagerEmail: this.financeManagerEmail,
          serviceManagerName: this.serviceManagerName,
          serviceManagerEmail: this.serviceManagerEmail,
        });
        this.$router.push('/onboarding/aparencia-checkout');
      }
    },
    returnCorrectManager(manager, prop) {
      return `${manager}${prop}`;
    },
    async handleCheckbox(managerName) {
      if (this[this.returnCorrectManager(managerName, 'Checkbox')]) {
        if (this.getUserName() === undefined) {
          const userData = await this.getUserById();
          this.user.name = `${userData.name} ${userData.lastname}`;
        } else {
          this.getUserName();
        }
        this[this.returnCorrectManager(managerName, 'Name')] = this.user.name;
        this[this.returnCorrectManager(managerName, 'Email')] = this.username;
      } else {
        this[this.returnCorrectManager(managerName, 'Name')] = '';
        this[this.returnCorrectManager(managerName, 'Email')] = '';
      }
    },
  },
  mounted() {
    Object.entries(this.managersData).forEach(([key, value]) => {
      if (value) this[key] = value;
    });
  },
};
</script>
